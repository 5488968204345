<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Books issued
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Books issued
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link"  v-if="checkIsAccessible('import-export', 'export-library-book')"
                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right
                  no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()" class="navi-link">
                        <span class="navi-icon">
                          <i class="fa fa-file-csv"></i>
                        </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body ">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getIssuedBooks()" autofocus outlined dense v-model="search.barcode"
                    label="Student ID card no." clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getIssuedBooks()" outlined dense v-model="search.name"
                    label="Symbol no., name, email" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getIssuedBooks()" outlined dense v-model="search.book_no" clearable
                    label="Book No"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-menu ref="menuDate" v-model="menuStartDate" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.start_date" label="View from date" readonly outlined dense clearable
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="search.start_date" outlined dense no-title @input="menuStartDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <v-menu ref="menuDate" v-model="menuEndDate" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.end_date" label="View up to date" readonly outlined dense clearable
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="search.end_date" outlined dense
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      no-title @input="menuEndDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select v-on:keyup.enter="getIssuedBooks()" v-model="search.filter_by" :items="filter_type" clearable
                    item-text="title" item-value="value" outlined dense label="Filter By"></v-select>
                </v-col>

                <v-col cols="12" md="3" v-if="search.filter_by == 'custom'">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.dates" label="Select by dates" prepend-icon="mdi-calendar" readonly
                        v-on:keyup.enter="getIssuedBooks()" v-bind="attrs" v-on="on" outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="search.dates" range></v-date-picker>

                  </v-menu>
                </v-col>

                <v-col cols="12" md="2" v-if="search.filter_by == 'custom'"></v-col>
                <v-col cols="12" md="5" v-if="search.filter_by != 'custom'"></v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn @click="getIssuedBooks()" class="btn btn-primary btn-search " :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
                <!--        <div class="col-md-6">
                          <v-btn class="btn btn-primary mr-2 text-white" @click="getIssuedBooks">Search</v-btn>
                          <v-btn class="btn btn-standard" @click="resetSearch">Reset</v-btn>
                          <v-btn class="btn btn-primary ml-2 text-white" @click="exportIssueList">Export</v-btn>
                        </div>-->
              </div>
            </div>
            <v-skeleton-loader type="table-thead" v-if="loading">
            </v-skeleton-loader>

            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
            </v-skeleton-loader>
            <div class="">
              <table class="table ">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">#</th>
                    <th class="px-3 wrap-column" style="max-width: 300px !important; white-space: pre-wrap;">Book title
                    </th>
                    <th class="px-3 wrap-column"></th>
                    <th class="px-3 wrap-column">User detail</th>
                    <th class="px-3 wrap-column">Dates</th>

                  </tr>
                </thead>
                <tbody v-if="issued_books.length > 0">
                  <tr v-for="(iss, i) of issued_books" :key="i">
                    <td class="px-3 wrap-column">
                      <div v-if="iss.book_no" class="text-secondary">
                        {{ iss.book_no }}
                      </div>
                      <div v-if="iss.book_accession_no" class="text-secondary">
                        {{ iss.book_accession_no }}
                      </div>
                    </td>
                    <td class="px-3 wrap-column" style="max-width: 300px !important; white-space: pre-wrap;">
                      <span class="font-weight-bold">
                        {{ iss.book_title }}
                      </span>
                      <div class="text-secondary">
                        <span class="font-weight-regular">{{ iss.book_author }}</span>
                      </div>
                    </td>
                    <td class="px-3 wrap-column"><img class="cursor-pointer img-thumbnail"
                        @click="redirectTODetail(iss.user_id, iss.user_type)" style="height: 80px" v-if="iss.user_image"
                        :src="iss.user_image" alt="">
                      <span v-else>-</span>
                    </td>
                    <td style="cursor:pointer; color:blue" class="wrap-column">
                      <div class="text-secondary">
                        <a @click="redirectTODetail(iss.user_id, iss.user_type)">
                          {{ iss.user_id_card_no }} - {{ iss.personal_id }}
                        </a>
                      </div>
                      <div class="text-secondary">
                        {{ iss.user_full_name }}
                      </div>
                      <div class="text-secondary">
                        {{ iss.program }}
                      </div>
                      <div class="text-secondary">
                        {{ iss.grade }} - {{ iss.class }}
                      </div>
                    </td>
                    <!--                    <td>{{ iss.user_phone }}</td>-->
                    <td class="wrap-column">
                      <div class="text-secondary">
                        <span class="badge badge-secondary">Issue date: {{ iss.issue_date_text ? iss.issue_date_text : '-'
                        }}</span>
                      </div>
                      <div class="text-secondary  mt-3">
                        <span class="badge badge-secondary">Renew date: {{
                          iss.due_return_date_text ? iss.due_return_date_text : '-'
                        }}</span>
                      </div>
                      <div class="text-secondary  mt-3">
                        <span class="badge badge-secondary">Return date: {{ iss.renew_date_text ? iss.renew_date_text : '-'
                        }}
                        </span>
                      </div>
                      <div class="text-secondary  mt-3 ml-2">
                        Remaining days: <span class="font-weight-medium "
                          :class="iss.renew_required ? 'text-danger' : 'text-success'">
                          {{ `${iss.renew_required ? '-' + iss.remaining_renew_day : iss.remaining_renew_day}` }}
                        </span>
                      </div>
                    </td>


                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="10">
                      <p class="px-3 text-center">
                        <strong>No data available to display.</strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination v-if="issued_books.length > 0" class="pull-right mt-7" @input="getIssuedBooks" v-model="page"
                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book = new BookService();
export default {
  data() {
    return {
      loading: false,
      issued_books: [],
      page: 1,
      total: null,
      perPage: null,
      search: {
        status: null,
        dates: [],
        name: null,
        book_no: null,
        filter_by: 'all',
        type: 'issued-list',
        start_date: '',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      filter_type: [
        { 'title': 'Today', 'value': 'today' },
        { 'title': 'Yesterday', 'value': 'yesterday' },
        { 'title': 'Current Week', 'value': 'this_week' },
        { 'title': 'Last Week', 'value': 'last_week' },
        { 'title': 'This Month', 'value': 'this_month' },
        { 'title': 'Custom', 'value': 'custom' },
        { 'title': 'All', 'value': 'all' },
      ],
      menuStartDate: false,
      menuEndDate: false,

    }
  },
  methods: {
    getIssuedBooks() {
      this.loading = true
      book
        .getAllIssueBook(this.search, this.page)
        .then(response => {
          this.issued_books = response.data.data
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportToCsv() {
      book
        .exportIssueBooksToCSV(this.search)
        .then(response => {
          this.$snotify.success('Export added to queue. You will receive an email upon completion');
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    redirectTODetail(userId, userType) {
      if(this.checkIsAccessible('user', 'list')) {
        this.$router.push({
          name: "students-summary",
          params: { id: userId, type: userType[0] }
        });
      }
    },
  },
  mounted() {
    this.getIssuedBooks();
  }
}
</script>
